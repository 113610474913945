﻿/*********************************************
 * Infosystem AG
 * Web Application CSS Standard
 * 
 *          -----> COLOURS <-----
 *
 ********************************************/

/*-----------------------------IMPORTS-----------------------------*/

/*-----------------------------VARIABLES-----------------------------*/
/* Colours */
$is-grey-dark: #585959;
$is-grey-light: #7f7f7f;
$is-red: #db0031; //Print red
$is-red-strong: #cf0032; //UI red
$is-red-dark: #db0031;
$is-black: #0f1820;
$is-white: #ffffff;

$is-text-title: #0f1820;
$is-text-sub-title: #585959;

$is-text: #0f1820;
$is-text-grey: #0f1820;

$body-bg: white;
$link-color: #242424;
$disabled-bg: #7f7f7f;
$background-color: #eee;

$is-sky: #92c0e9;
$is-sky-80: #92c0e980;
$is-sky-60: #92c0e960;
$is-sky-40: #92c0e940;
$is-sky-contrast: #000000;

$is-orange: #feaa61;
$is-orange-80: #feaa6180;
$is-orange-60: #feaa6160;
$is-orange-40: #feaa6140;
$is-orange-contrast: #000000;

$is-mint: #94cca7;
$is-mint-80: #94cca780;
$is-mint-60: #94cca760;
$is-mint-40: #94cca740;
$is-mint-contrast: #000000;

/*Content*/
$is-grey-dark: #242424;
$is-grey-dark-80: #24242480;
$is-grey-dark-60: #24242460;
$is-grey-dark-40: #24242440;
$is-grey-contrast: #ffffff;

$is-grey: #585959;
$is-grey-80: #58595980;
$is-grey-60: #58595960;
$is-grey-40: #58595940;
$is-grey-contrast: #000000;

/*Success indicators, toasts, table cells*/
$is-green: #86b519;
$is-green-80: #86b51980;
$is-green-60: #86b51960;
$is-green-40: #86b51940;
$is-green-contrast: #000000;

/*Warning indicators, toasts, table cells*/
$is-yellow: #ffcc00;
$is-yellow-80: #ffcc0080;
$is-yellow-60: #ffcc0060;
$is-yellow-40: #ffcc0040;
$is-yellow-contrast: #000000;

/* Red Error indicators, toasts, table cells */
$is-red: #cf0032;
$is-red-80: #cf003280;
$is-red-60: #cf003260;
$is-red-40: #cf003240;
$is-red-10: #cf003210;
$is-red-contrast: #ffffff;
