@import 'node_modules/@infosystem/baf-angular-web-ui/assets/scss/infosystem-colors.scss';

//* style variables */
$b2x-site-max-width: 1024px;

// genius robot icon settings
$bx2-issue-icon-max-width: calc($b2x-site-max-width * .375);
$bx2-issue-icon-max-height: $bx2-issue-icon-max-width;

// header settings
$b2x-supplier-logo-height: 44px; // 60px;
$b2x-header-auto-hide-offset: 200px;

/* footer settings */
$b2x-footer-font-color: rgba($is-white, .7);
$b2x-footer-bg-color: #191F25;

$b2x-footer-base-size-multiplier: .5;
$b2x-footer-base-design-width: 800px;

// total height of the footer
$b2x-footer-height: calc(30px + $b2x-footer-base-size-multiplier * 170px); // 200px

// margins on the sides of the content
$b2x-footer-horizontal-padding: calc($b2x-footer-base-size-multiplier * 100px);

// spacing between elements in the content
$b2x-footer-horizontal-spacing: calc($b2x-footer-base-size-multiplier * 33px);
// spacing before the language selectors
$b2x-footer-horizontal-spacing-loc: calc($b2x-footer-base-size-multiplier * 11px);

// base content height
$b2x-footer-base-content-height: calc($b2x-footer-base-size-multiplier * 55px);
$b2x-footer-base-content-padding: calc($b2x-footer-base-size-multiplier * 15px);

// dimensions for the genius and leva logos
$b2x-footer-logo-height: $b2x-footer-base-content-height;

// Based on the design. Currently ignored in css as they would distort the provided pictures
// $b2x-footer-leva-logo-width: calc($b2x-footer-base-size-multiplier * 168px);
// $b2x-footer-genius-logo-width: calc($b2x-footer-base-size-multiplier * 214px);

$b2x-footer-separator-line-height: calc($b2x-footer-base-content-height + 2 * $b2x-footer-base-content-padding);
$b2x-footer-separator-line-width: calc($b2x-footer-base-size-multiplier * .75px);

// overview card
$b2x-card-bg-color: #FBF9F6;

$b2x-card-mark-bg-color: #ffcc0018;
$b2x-card-mark-border-color: #D1E6F8;

// contact button
$b2x-button-tertiary-bg-color: #191F25;
$b2x-button-tertiary-font-color: $is-white;

// action buttons
$b2x-button-bg-color: $is-black;
$b2x-button-font-color: $is-white;

// cancel/back/secondary choice buttons
$b2x-button-secondary-bg-color: #E0E0E2;
$b2x-button-secondary-font-color: $is-grey-contrast;

// state headers
$b2x-state-confirmed-bg-color: #D2E8DC;
$b2x-state-overdue-bg-color: #F2AEA4;
$b2x-state-soon-bg-color: #D1E6F8;
$b2x-state-info-bg-color: #FDDBBD;
$b2x-state-pending-bg-color: #D1E6F8;

// accordion group
$b2x-accordion-bg-color: #E0E0E2;
$b2x-accordion-counter-bg-color: #191F25;

// stepper
$b2x-stepper-pending-bg-color: #E0E0E2;
$b2x-stepper-bg-color: $is-black;
$b2x-stepper-font-color: $is-white;
$b2x-stepper-pending-line-color: #E0E0E2;
$b2x-stepper-line-color: $is-black;

$b2x-stepper-tag-completed-color: #40c27b;
$b2x-stepper-tag-rejected-color: #f07a68;
$b2x-stepper-tag-pending-color: #98a7b3;

// lists
$b2x-list-item-selected-bg-color: #E0E0E2;
$b2x-list-item-selected-font-color: $is-grey-contrast;

// datepicker
$b2x-datepicker-today-border-color: #191F25;

/* for the scroll-down reload support on smartphones  */
html, body {
  overscroll-behavior-y: auto;
  overscroll-behavior-x: contain;
}

body {
  overflow-y: visible;
  overflow-x: auto;
}

//* custom styling */

.action-button {
  background-color: $b2x-button-bg-color;
  color: $b2x-button-font-color !important;
  padding: 1em 1.25em;
  border-radius: 8px;
  border-width: 0;
  display: block;
  width: 100%;
}

.action-button:hover:enabled {
  background-color: lighten($b2x-button-bg-color, 5%);
}

.action-button:focus:enabled {
  background-color: lighten($b2x-button-bg-color, 5%);
}

.action-button:disabled, .action-button[disabled] {
  cursor: not-allowed;
  background-color: $disabled-bg;
  opacity: .25;
}

/* secondary / cancel buttons */
.action-button.secondary {
  background-color: $b2x-button-secondary-bg-color;
  color: $b2x-button-secondary-font-color !important;
}

.action-button.secondary:hover:enabled {
  background-color: lighten($b2x-button-secondary-bg-color, 5%);
}

.action-button.secondary:focus:enabled {
  background-color: lighten($b2x-button-secondary-bg-color, 5%);
}

/* back (X) button on pages */
.back-button {
  color: $b2x-button-secondary-font-color !important;
  width: auto;
  padding: .2em .6em;
  margin-top: .5em;
  background-color: transparent;
}

.back-icon {
  margin-right: 0;
  font-weight: bold;
  vertical-align: middle;
}

.back-button:hover:enabled {
  background-color: $b2x-button-secondary-bg-color;
}

.back-button:focus:enabled {
  background-color: $b2x-button-secondary-bg-color;
}


//* kendo overrides */

// sticky action area
.k-actions.k-actions-end {
  position: sticky;
  bottom: 6px;
  background-color: $b2x-card-bg-color;
  border: 1px solid rgba(0, 0, 0, .5);
  z-index: 5;
  padding: 1em 1.25em;
}

.k-actions .k-button-md {
  line-height: 1.2;
}

@media screen and (min-width: $b2x-site-max-width) and (min-height: 1080px) {
  .k-actions.k-actions-end {
    position: absolute;
    right: 14px;
    bottom: -28px;
    width: -webkit-fill-available; // Chromium knows this
    width: -moz-available; // Firefox only knows this
  }
}

/* dropdown */
kendo-dropdownlist {
  min-height: 48px !important;
}

kendo-dropdownlist .dropdown-selected {
  display: inline-block;
  vertical-align: middle;
}

/* time pickers */
kendo-timepicker {
  min-height: 48px !important;
  padding-left: 8px !important;
}

/* date pickers */
kendo-datetimepicker {
  min-height: 48px !important;
  padding-left: 8px !important;
}

kendo-datetimepicker .k-input .k-input-inner, kendo-timepicker .k-input .k-input-inner {
  font-size: 15px !important;
}

kendo-timepicker .k-input-solid:focus-within, kendo-datetimepicker .k-input-solid:focus-within {
  box-shadow: none;
}

kendo-popup {
  // let it appear on top of the sticky header
  z-index: 1005 !important;
}

// flat button (Today/Now)
kendo-popup .k-button-flat-primary {
  background-color: $b2x-button-bg-color !important;
  color: $b2x-button-font-color !important;
}

kendo-popup .k-button-flat-primary:hover:enabled {
  background-color: lighten($b2x-button-bg-color, 5%);
}

// secondary button on date and timepicker popup
kendo-popup .k-button-solid-base {
  background-color: $b2x-button-secondary-bg-color !important;
  color: $b2x-button-secondary-font-color !important;
  border-radius: 8px;
  border-width: 0;
}

// primary button on date and timepicker popup and BAF alerts
kendo-popup .k-button-solid-primary, kendo-dialog-actions .k-button-solid-primary {
  background-color: $b2x-button-bg-color !important;
  color: $b2x-button-font-color !important;
  border-radius: 8px;
  border-width: 0;
}

kendo-popup .k-button-solid-primary:hover:enabled, kendo-dialog-actions .k-button-solid-primary:hover:enabled {
  background-color: lighten($b2x-button-bg-color, 5%);
}

kendo-dialog-actions .k-button-solid-primary:focus, .k-button-solid-primary.k-focus {
  box-shadow: 0 0 0px 0.25rem lighten($b2x-button-secondary-bg-color, 5%);
}

// current month/today on monthly view
kendo-popup .k-calendar .k-calendar-view .k-today .k-link {
  box-shadow: inset 0 0 0 1px $b2x-datepicker-today-border-color !important;
}

// selected date
kendo-popup .k-calendar .k-calendar-td.k-selected.k-focus .k-link {
  background-color: $b2x-button-secondary-bg-color !important;
  color: $b2x-button-secondary-font-color !important;
  box-shadow: none;
}

kendo-popup .k-calendar .k-calendar-td.k-selected:focus .k-link {
  background-color: $b2x-button-secondary-bg-color !important;
  color: $b2x-button-secondary-font-color !important;
  box-shadow: none;
}

kendo-popup .k-calendar .k-calendar-td.k-selected .k-link {
  background-color: $b2x-button-secondary-bg-color !important;
  color: $b2x-button-secondary-font-color !important;
  box-shadow: none;
}

// time selection
kendo-popup .k-time-list .k-item:hover {
  color: $b2x-button-secondary-font-color !important;
  font-weight: bold;
}

kendo-list .k-list-item.k-selected {
  background-color: $b2x-list-item-selected-bg-color;
  color: $b2x-list-item-selected-font-color;
}

kendo-list .k-list-item.k-selected:hover {
  background-color: lighten($b2x-list-item-selected-bg-color, 5%);
  color: $b2x-list-item-selected-font-color;
}

.k-timeselector {
  min-width: 320px;
}

@media screen and (min-width: $b2x-site-max-width) {
  .k-timeselector {
    min-width: 280px;
  }
}

/* Popup dialog  */
kendo-dialog.bg-light, kendo-dialog.bg-dark {
  $kendo-overlay-opacity: .9;
  --bs-bg-opacity: .8;
}

/* Accordions  */
kendo-panelbar .k-link.k-selected {
  background-color: $b2x-accordion-bg-color !important;
  color: $is-white !important;
}

kendo-panelbar .k-link {
  background-color: $b2x-accordion-bg-color !important;
  color: $is-white !important;
  padding-block: .5rem !important;
}

kendo-panelbar .k-link:hover {
  background-color: darken($b2x-accordion-bg-color, 10%) !important;
  color: $is-white !important;
}

/* Stepper  */
// done steps
.k-stepper .k-step-done.k-step-disabled .k-step-indicator, .k-stepper .k-step-done.k-disabled .k-step-indicator, .k-stepper .k-step-done:disabled .k-step-indicator {
  border-color: $b2x-stepper-line-color;
  color: $b2x-stepper-font-color;
  background-color: $b2x-stepper-bg-color;
}

// current step
.k-stepper .k-step-current.k-step-disabled .k-step-indicator, .k-stepper .k-step-current.k-disabled .k-step-indicator, .k-stepper .k-step-current:disabled .k-step-indicator {
  border-color: $b2x-stepper-line-color;
  color: $b2x-stepper-font-color;
  background-color: $b2x-stepper-bg-color;
  font-weight: normal !important;
}

.k-progressbar
{
  background-color: $b2x-stepper-pending-line-color;
}

.k-progressbar .k-selected
{
  background-color: $b2x-stepper-line-color;
}

//* BAF overrides */

baf-alert-modal .k-window.k-dialog {
	width: auto !important;
}
