<div id="issue" class="outer-container">
  <div class="content-container text-center">
    <img class="logo mt-2" src="assets/images/24-inf-genius-icon-color-question.png" alt="Genius by Infosystem, error logo" />
    <p class="mt-3 h4">
      <span class="fw-semibold">{{ 'frontend.not-found.code.text' | translate }}</span>
      {{ 'frontend.not-found.title' | translate }}
    </p>
    <p>
      @if(reason === 'job') {
        {{ 'frontend.not-found.job.text' | translate }}
      } @else {
        {{ 'frontend.not-found.order.text' | translate }}
      }
    </p>
  </div>
</div>
