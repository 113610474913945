<div kendoTooltip id="wrapper">
    <div id="main-wrapper" >
      <!-- Sticky header -->
      <kendo-appbar *ngIf="service.order() && supplier(); else noOrderHeader" id="header" #appBar
        class="container content-block px-3" position="top" fillMode="flat" positionMode="fixed"
        [ngClass]="{'hide-appbar' : hideAppBar}">
        <!-- default header -->
        <!-- Supplier logo -->
        <a (click)="onSupplierLogoClick()"
          class="mx-auto supplier-logo-link"
          [ngClass]="{
            'ms-0': supplier()?.logoAlignment === LogoAlignment.Left,
            'me-0': supplier()?.logoAlignment === LogoAlignment.Right
          }">
          <img [src]="supplierLogoUrl"
          alt="{{ supplier()?.name}}"
          class="supplier-logo"
          (error)="onSupplierLogoLoadingError()"/>
        </a>
        <!-- Order details -->
        <div class="container-fluid order-header">
          <h2 class="title">
            {{ order()?.title ?? 'frontend.order.missing-title.error.text' | translate}}
            <span title="{{ 'frontend.order.sync-disabled.text' | translate}}" class="h4 badge-disabled fw-semibold" *ngIf="order()?.synchronizationDisabled">{{ 'frontend.order.sync-disabled.title' | translate}}</span>
          </h2>
          <div class="row">
            <div class="col-6">
              <p class="my-0">{{ 'frontend.order.number.title' | translate }}</p>
              <p class="my-0 h4 text-uppercase fw-semibold">{{ order()?.orderNumber ?? 'frontend.order.missing-number.error.text' | translate}}</p>
            </div>
            <!-- Supplier Contact -->
            <div class="col-6 text-end align-self-end contact-container">
              <button kendoButton class="subtle-button" #contactBtn (click)="onContactToggle()">
                {{ 'frontend.order.supplier.contact.label' | translate}}
              </button>
              <kendo-popup #contactPopup
                popupClass="popup"
                [anchor]="contactBtn.element"
                [anchorAlign]="contactAnchorAlign"
                [popupAlign]="contactPopupAlign"
                (anchorViewportLeave)="showContact = false"
                *ngIf="showContact">
                <div id="contact-info">
                  <p class="mb-0">{{ 'frontend.order.supplier.contact-phone.title' | translate}}</p>
                  <a class="text-decoration-none h4 fw-semibold" href="{{ 'tel:' + order()?.supplierContactPhone }}">{{ order()?.supplierContactPhone && order()?.supplierContactPhone !== '' ? order()?.supplierContactPhone : 'frontend.order.supplier.missing-phone-number.error.text' | translate}}</a>
                </div>
              </kendo-popup>
            </div>
          </div>
          <hr class="mb-0">
        </div>
      </kendo-appbar>
      <!-- Alternative header for no order/supplier data scenario -->
      <ng-template #noOrderHeader>
        <!-- Leva+ logo -->
        <img id="header" src="assets/images/logo-genius.png" alt="{{ 'frontend.white-labeling.powered-by.text' | translate }} Leva+" title="{{ 'frontend.white-labeling.powered-by.text' | translate }} Leva+" class="supplier-logo mx-auto" />
      </ng-template>
      <!-- Content -->
      <div id="content-wrapper" #contentWrapper class="container content-block px-3">
        <router-outlet></router-outlet>
      </div>
      <!-- Footer -->
      <div id="footer" class="container content-block">
        <div class="content-container mt-3 px-3 d-flex justify-content-between align-items-center">
          <div class="col"></div>
          <div class="col-auto d-flex content">
            <span class="text">{{ 'frontend.white-labeling.powered-by.text' | translate}}</span>
            <a href="https://www.infosystem.ch/leva" target="_blank">
              <img src="assets/images/logo-leva-negative.png" alt="leva+ logo" class="logo" />
              <div class="separator"></div>
              <img src="assets/images/logo-genius-inf-negative.png" alt="Genius by Infosystem logo" class="genius-logo" />
            </a>
          </div>
          <div class="col justify-content-end list-unstyled d-flex">
            <div class="separator"></div>
            <ul class="nav">
              @for (language of localizationService.getLanguageSelectOptions(); track $index) {
                <li class="text mx-0">
                  <button class="lang-button link-light link-offset-1 link-underline-opacity-0"
                    [ngClass]="localizationService.getLocaleAsTwoLetterCode() === language ? 'fw-bold' : 'fw-normal'"
                    (click)="localizationService.changeLanguage(language)">
                    {{language.toUpperCase()}}
                  </button>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- General modal dialogs -->
    <baf-alert-modal></baf-alert-modal>
    <baf-error-modal></baf-error-modal>
    <baf-busy></baf-busy>
</div>
