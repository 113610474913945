import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArrowsRotate,
  faCheck,
  faCircleCheck,
  faClock,
  faExclamationSquare,
  faInfoCircle,
  faRotate,
  faTriangleExclamation,
  faXmark
} from "@fortawesome/pro-light-svg-icons";

/** Initializes the icons used in the application. */
export function initializeAppIcons(iconLibrary: FaIconLibrary): void {
  iconLibrary.addIcons(
    faXmark,
    faCheck,
    faClock,
    faCircleCheck,
    faArrowsRotate,
    faInfoCircle,
    faTriangleExclamation,
    faRotate,
    faExclamationSquare,
  );
}
