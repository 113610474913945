@import '../styles.scss';

#wrapper {
  height: 100svh;
}

#main-wrapper {
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;

  flex-direction: column;
  overflow: visible;
}

#content-wrapper {
  align-content: start;
  flex: 1 0 auto;
}

/* header and footer styles */
footer,
#header {
  align-content: start;
  flex: none;
}

// header item
.k-appbar {
  box-shadow: none;
  padding-block-end: 0;
  padding-block-start: 0;
  transition: top 250ms ease-in-out;
  gap: 0;

  // only required if set to fixed, not sticky
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/* dynamic class for scrolling */
.hide-appbar {
  top: -$b2x-header-auto-hide-offset;
}

// sets the main blocks
.content-block {
  max-width: $b2x-site-max-width;
}

// the container of the relevant part
.order-header {
  padding: 0 !important;
  margin: 0 !important;
}

// header content styles
.supplier-logo-link{
  cursor:pointer;
}

.supplier-logo {
  height: $b2x-supplier-logo-height;
  display: block;
  margin: 12px 0px 8px 0px;
}

.badge-disabled {
  text-transform:  uppercase;
  font-weight: bolder;
  background-color: $b2x-accordion-bg-color;
  border-radius: 4px;
  padding: 4px 10px;
  display: inline-block;
}

// responsive sizes for footer elements
:root {
  --footer-relative-size: 1;
}

@media screen and (max-width: $b2x-site-max-width) {
  :root {
    --footer-relative-size: .75;
  }
  #footer {
    --bs-gutter-x : 0 !important;
  }
  #footer .text {
    padding-top: calc($b2x-footer-base-content-padding * var(--footer-relative-size) * .5) !important;
  }
}

@media screen and (max-width: $b2x-footer-base-design-width) {
  :root {
    --footer-relative-size: .65;
  }
  #footer .text {
    padding-top: 0 !important;
    font-size: smaller;
  }

  #footer .content {
    padding-left: 0 !important;
  }
}

// larger smartphones - iphone plus/pro max are 430px, Pixel 7 is 412px
@media screen and (max-width: 440px) {
  :root {
    --footer-relative-size: .5;
  }
}

// medium or smaller smartphones - normal iphone 15/pro is 393px
@media screen and (max-width: 399px) {
  :root {
    --footer-relative-size: .4;
  }
}


#footer .content-container {
  background-color: $b2x-footer-bg-color;
  height: calc($b2x-footer-height * var(--footer-relative-size));
  color: $b2x-footer-font-color;
}

#footer .content {
  padding: 0 calc($b2x-footer-horizontal-padding * var(--footer-relative-size));
}

#footer .nav {
  padding-left: calc($b2x-footer-horizontal-spacing-loc * var(--footer-relative-size));
}

#footer .text {
  padding-top: calc($b2x-footer-base-content-padding * var(--footer-relative-size));
}

#footer .separator {
  display: inline-block;
  vertical-align: middle;
  height: calc($b2x-footer-separator-line-height * var(--footer-relative-size));
  border-left: calc($b2x-footer-separator-line-width * var(--footer-relative-size)) solid $b2x-footer-font-color;
}

#footer .logo {
  vertical-align: middle;
  height: calc($b2x-footer-logo-height * var(--footer-relative-size));
  margin: 0 calc($b2x-footer-horizontal-spacing * var(--footer-relative-size));
  // width: $b2x-footer-leva-logo-width;
}

#footer .genius-logo {
  margin-left: calc($b2x-footer-horizontal-spacing * var(--footer-relative-size));
  height: calc($b2x-footer-logo-height * var(--footer-relative-size));
  // width: $b2x-footer-genius-logo-width;
}

/* contact area */
.contact-container {
  margin-bottom: -5px;
}

#contact-info {
  margin: 4px 6px;
  padding: 4px;
}

// contact popup container
.popup {
  margin-bottom: 0;
  margin-right: 10px;
  color: $is-black;
  background-color: $is-white;
  box-shadow: none;
  border-width: 1px;
  border-radius: 8px;
}

// ie. contact button
.subtle-button {
  color: $b2x-button-tertiary-font-color;
  width: auto;
  padding: .2em .6em;
  margin-top: .5em;
  background-color: $b2x-button-tertiary-bg-color;

  border-radius: 8px;
  border-width: 0;
}

.subtle-button:hover:enabled {
  background-color: $b2x-button-tertiary-bg-color;
  color: $is-white;
}

.subtle-button:focus:enabled {
  background-color: $b2x-button-tertiary-bg-color;
  color: $is-white;
}

.centered-icon {
  vertical-align: middle;
}

.lang-button {
  width: auto;
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}

/* For comments and texts with line-breaks  */
.preserve-text {
  white-space: pre-line;
}

// home and page-not-found
#issue .logo, #home .logo {
  max-width: min($bx2-issue-icon-max-width, 66svw);
  max-height: min($bx2-issue-icon-max-height, 33svh);
}

#issue .outer-container, #home .outer-container {
  min-height: calc($bx2-issue-icon-max-height * .66);
}

#issue .content-container, #home .content-container {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: min($bx2-issue-icon-max-width, 66svw);
  height: fit-content;
}
